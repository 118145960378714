import {
  courseBaseUrl,
  overviewUrl,
  overviewCoursesUrl,
  overviewUsersUrl,
  overviewInstitutionsUrl,
  //Overview
  courseDashboardUrl,
  courseGradingStatusUrl,
  courseMultiplierChoicesUrl,
  courseEarnedBadgesUrl,
  courseDataExportsUrl,
  courseAnalyticsAssignmentsUrl,
  courseAnnouncementListUrl,
  courseEventsUrl,

  // Coursework
  courseAssignmentsUrl,
  courseBadgesListPageUrl,
  courseChallengesUrl,
  courseAttendanceUrl,
  courseGradebookUrl,

  // Users
  courseStudentsUrl,
  courseTeamsUrl,
  courseGroupsUrl,
  courseStaffUrl,
  courseObserversUrl,

  // Course Setup
  courseEditUrl,
  courseLearningObjectivesStaffIndexUrl,
  courseLearningObjectivesStudentUrl,
  courseAssignmentsSettingsUrl,
  coursePredictorUrl,
  courseGradeSchemeElementsUrl,

  // Admin (as seen in old sidebar)
  courseNewUrl,
  courseOverviewUrl,
  courseMembershipsUrl,
  institutionsUrl,
  courseUsersUrl,
  userSearchUrl,
  courseJobsUrl,
  courseAdminToolsUrl,
  ltiKeysUrl,
  ltiPlatformInstancesUrl,
  ltiRegistrationsUrl,
  ltiDeploymentsUrl,
  ltiContextsUrl,

  // Other
  courseSyllabusUrl,
} from "@/courses/urls.js";

import {
  getCourseCreationChecklist,
  updateCourseCreationChecklist,
  getNavigationCounts,
} from "@/courses/services/NavigationService.js";
import { cloneDeep } from "lodash";
const courseBase = courseBaseUrl.match(location.pathname);
export const navigationModule = {
  namespaced: true,
  state: () => ({
    courseId: courseBase ? courseBase.courseId : null,
    courseCreationProgress: {},
    navCounts: {},
    navOpenDefaults: {
      Overview: true,
      Setup: false,
      Users: false,
      Coursework: true,
      Admin: false,
    },
    adminOverviewNavItemsList: [
      { pattern: overviewUrl.stringify(), text: "Dashboard", key: "overview" },
      {
        pattern: overviewCoursesUrl.stringify(),
        text: "Courses",
        key: "courses",
      },
      { pattern: overviewUsersUrl.stringify(), text: "Users", key: "users" },
      {
        pattern: overviewInstitutionsUrl.stringify(),
        text: "Institutions",
        key: "institutions",
      },
    ],
    courseStaffNavList: [
      {
        name: "Overview",
        sectionIcon: "cubes",
        items: [
          { pattern: courseDashboardUrl, text: "Course Dashboard" },
          {
            pattern: courseGradingStatusUrl,
            text: "Grading Status",
            hasCounts: true,
            countType: "grading_status",
          },
          { pattern: courseAnnouncementListUrl, text: "Announcements" },
          { pattern: courseEventsUrl, text: "Events" },
          { pattern: courseAnalyticsAssignmentsUrl, text: "Analytics" },
          { pattern: courseDataExportsUrl, text: "Data Exports" },
        ],
      },
      {
        name: "Coursework",
        sectionIcon: "folder-open",
        items: [
          { pattern: courseGradebookUrl, text: "Gradebook" },
          { pattern: courseAttendanceUrl, text: "Attendance" },
          {
            pattern: courseMultiplierChoicesUrl,
            text: "Selected (TermFor)",
            term: "term_for_weights",
            showCondition: "student_weighted",
          },
          {
            pattern: coursePredictorUrl,
            text: "(TermFor)",
            term: "term_for_grade_predictor",
            showCondition: "show_grade_predictor",
          },
          {
            pattern: courseAssignmentsUrl,
            text: "(TermFor)",
            term: "term_for_assignments",
          },
          {
            pattern: courseEarnedBadgesUrl,
            text: "Awarded (TermFor)",
            term: "term_for_badges",
            showCondition: "has_badges",
          },
        ],
      },
      {
        name: "Users",
        sectionIcon: "user",
        items: [
          {
            pattern: courseStudentsUrl,
            text: "(TermFor)",
            term: "term_for_students",
          },
          {
            pattern: courseTeamsUrl,
            text: "(TermFor)",
            term: "term_for_teams",
            showCondition: "has_teams",
          },
          {
            pattern: courseGroupsUrl,
            text: "(TermFor)",
            term: "term_for_groups",
            hasCounts: true,
            countType: "groups_to_review",
          },
          { pattern: courseStaffUrl, text: "Staff" },
          { pattern: courseObserversUrl, text: "Observers" },
        ],
      },
      {
        name: "Setup",
        sectionIcon: "folder",
        items: [
          { pattern: courseEditUrl, text: "Course Settings" },
          {
            pattern: courseAssignmentsSettingsUrl,
            text: "(TermFor) Settings",
            term: "term_for_assignment",
          },
          {
            pattern: courseLearningObjectivesStaffIndexUrl,
            text: "(TermFor)",
            term: "term_for_learning_objectives",
            showCondition: "has_learning_objectives",
          },
          {
            pattern: courseBadgesListPageUrl,
            text: "(TermFor)",
            term: "term_for_badges",
            showCondition: "has_badges",
          },
          {
            pattern: courseChallengesUrl,
            text: "(TermFor)",
            term: "term_for_challenges",
            showCondition: "has_team_challenges",
          },
          { pattern: courseGradeSchemeElementsUrl, text: "Grading Scheme" },
        ],
      },
      {
        name: "Admin",
        sectionIcon: "gears",
        items: [
          { pattern: courseNewUrl, text: "Add a New Course" },
          { pattern: courseOverviewUrl, text: "Manage Courses" },
          { pattern: courseMembershipsUrl, text: "Delete Course Memberships" },
          { pattern: institutionsUrl, text: "All Institutions" },
          { pattern: courseUsersUrl, text: "Current Course Users" },
          { pattern: userSearchUrl, text: "Search Users" },
          { pattern: courseAdminToolsUrl, text: "Tools" },
          { pattern: courseJobsUrl, text: "Jobs" },
          { pattern: ltiKeysUrl, text: "LTI Keys" },
          { pattern: ltiPlatformInstancesUrl, text: "LTI Platform Instances" },
          { pattern: ltiRegistrationsUrl, text: "LTI Registrations" },
          { pattern: ltiDeploymentsUrl, text: "LTI Deployments" },
          { pattern: ltiContextsUrl, text: "LTI Contexts" },
        ],
      },
    ],
    courseStudentNavList: [
      { pattern: courseDashboardUrl, text: "Course Dashboard" },
      {
        pattern: courseAssignmentsUrl,
        text: "(TermFor)",
        term: "term_for_assignments",
      },
      {
        pattern: courseAttendanceUrl,
        text: "Attendance",
        showCondition: "has_attendance",
      },
      { pattern: courseSyllabusUrl, text: "Syllabus" },
      {
        pattern: coursePredictorUrl,
        text: "(TermFor)",
        term: "term_for_grade_predictor",
        showCondition: "show_grade_predictor",
      },
      {
        pattern: courseBadgesListPageUrl,
        text: "(TermFor)",
        term: "term_for_badges",
        showCondition: "has_badges",
      },
      {
        pattern: courseTeamsUrl,
        text: "(TermFor)",
        term: "term_for_teams",
        showCondition: "teams_visible",
      },
      {
        pattern: courseLearningObjectivesStudentUrl,
        text: "(TermFor)",
        term: "term_for_learning_objectives",
        showCondition: "has_learning_objectives",
      },
      { pattern: courseEventsUrl, text: "Events", showCondition: "has_events" },
    ],
    courseObserverNavList: [
      {
        pattern: courseAssignmentsUrl,
        text: "(TermFor)",
        term: "term_for_assignments",
      },
      {
        pattern: courseAttendanceUrl,
        text: "Attendance",
        showCondition: "has_attendance",
      },
      { pattern: courseSyllabusUrl, text: "Syllabus" },
      {
        pattern: coursePredictorUrl,
        text: "(TermFor)",
        term: "term_for_grade_predictor",
        showCondition: "show_grade_predictor",
      },
      {
        pattern: courseBadgesListPageUrl,
        text: "(TermFor)",
        term: "term_for_badges",
        showCondition: "has_badges",
      },
    ],
  }),
  actions: {
    async getCourseCreationProgress({ commit }) {
      const data = await getCourseCreationChecklist();
      commit("setCourseCreationProgress", data);
    },
    async updateCourseCreationItem({ commit }, item) {
      const data = await updateCourseCreationChecklist(item);
      commit("setCourseCreationProgress", data);
    },
    async loadNavigationCounts({ commit }, courseId) {
      const data = await getNavigationCounts(courseId);
      commit("setNavigationCounts", data);
    },
  },
  getters: {
    courseStaffNavItems: (state, getters, rootState) => {
      const navlist = cloneDeep(state.courseStaffNavList);
      const courseId = { courseId: state.courseId };
      for (let obj of navlist) {
        obj["items"] = obj["items"].map((navitem) =>
          urlProcessor(state, navitem, courseId, rootState),
        );
      }
      return navlist;
    },
    adminOverviewNavItems: (state) => {
      const navlist = state.adminOverviewNavItemsList;
      return navlist.map(function (navitem) {
        navitem.url = navitem.pattern;
        navitem.show = true;
        navitem.active = navitem.url === location.pathname;
        return navitem;
      });
    },
    courseStudentNav: (state, getters, rootState) => {
      const navlist = state.courseStudentNavList;
      const courseId = { courseId: state.courseId };
      return navlist.map((navitem) =>
        urlProcessor(state, navitem, courseId, rootState),
      );
    },
    courseObserverNav: (state, getters, rootState) => {
      const navlist = state.courseObserverNavList;
      const courseId = { courseId: state.courseId };
      return navlist.map((navitem) =>
        urlProcessor(state, navitem, courseId, rootState),
      );
    },
  },
  mutations: {
    setCourseId(state, sessionId) {
      state.courseId = sessionId;
    },
    setCourseCreationProgress(state, data) {
      state.courseCreationProgress = data;
    },
    setNavigationCounts(state, data) {
      state.navCounts = data;
    },
    setNavOpenDefaults(state) {
      if (localStorage.getItem("sidenavCollapsePreferences")) {
        state.navOpenDefaults = JSON.parse(
          localStorage.getItem("sidenavCollapsePreferences"),
        );
      }
    },
    updateNavOpenDefaults(state, payload) {
      state.navOpenDefaults[payload.name] = payload.value;
      localStorage.setItem(
        "sidenavCollapsePreferences",
        JSON.stringify(state.navOpenDefaults),
      );
    },
  },
};

function urlProcessor(state, navitem, courseId, rootState) {
  navitem.text = navitem.text.replace(
    "(TermFor)",
    rootState.course.currentCourse[navitem.term],
  );
  navitem.url = navitem.pattern.stringify(courseId);
  navitem.active = navitem.url === location.pathname;

  // Hides links to optional features
  if (navitem.showCondition) {
    navitem.show = rootState.course.currentCourse[navitem.showCondition];
  } else {
    navitem.show = true;
  }
  // For grading status and groups to review
  if (navitem.hasCounts) {
    navitem.count = state.navCounts[navitem.countType];
  }
  return navitem;
}
